@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Roboto-Regular;
    src: url('/src/assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: Roboto-Bold;
    src: url('/src/assets/fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: Roboto-Black;
    src: url('/src/assets/fonts/Roboto-Black.ttf');
}

@layer base {
    :root {
        --color-gray-dark: 83 86 90;
        --color-gray-light: 154 154 154;
        --color-gray-base: 244 244 244;
        --color-green-base: 0 150 58;
    }
    html {
        font-family: 'Roboto-Regular';
    }
}

@layer components {
    .page-background {
        background-color: rgb(var(--color-gray-base));
    }

    th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
}


.quote-card {
    padding: 20px;
    padding-left: 50px;
    box-sizing: border-box;

    position: relative;
    overflow: hidden;
    min-height: 120px;
}

.quote-card p {
    font-size: 22px;
    line-height: 1.5;
    margin: 0;
    max-width: 80%;
}

.quote-card cite {
    text-align: right;
    font-size: 16px;
    margin-top: 10px;
    display: block;
    font-weight: 200;
    opacity: 0.8;
}

.recharts-tooltip-wrapper{
    z-index: 1;
}
